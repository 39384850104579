<template>
  <div class="contain">
    <div class="modalityTop">
      <el-breadcrumb separator-class="el-icon-arrow-right" class="crumbs">
        <el-breadcrumb-item>巡线系统管理</el-breadcrumb-item>
        <el-breadcrumb-item>巡检管理</el-breadcrumb-item>
        <el-breadcrumb-item><span>任务配置</span></el-breadcrumb-item>
      </el-breadcrumb>
      <el-form
        ref="search"
        class="searchForm"
        :model="paramsData"
        label-width="auto"
      >
        <el-form-item label="时间段" prop="equipmentStatus">
          <el-date-picker
            @change="search"
            style="width:250px"
            v-model="DataPicker"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            value-format="yyyy-MM-dd HH:mm:ss"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="巡检区域" prop="equipmentStatus">
          <el-select
            clearable
            style="width:170px"
            v-model="paramsData.networkId"
            placeholder="请选择巡检区域"
          >
            <el-option
              v-for="item in selectDownArray"
              :key="item.id"
                   :label="item.network_name"
            :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="巡线员" prop="equipmentStatus" >
          <el-select
            clearable
            style="width:170px"
            v-model="paramsData.userId"
            placeholder="请选择巡线员"
          >
            <el-option
              v-for="item in excutorDownArray"
              :key="item.id"
              :label="item.username"
              :value="item.id">
            
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="任务状态" prop="equipmentStatus" >
          <el-select
            clearable
            style="width:170px"
            v-model="paramsData.taskGenerateStatus"
            placeholder="请选择状态"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字" prop="equipmentStatus">
          <el-input
          clearable
            placeholder="请输入任务编号/任务名称/执行人员姓名"
            v-model="paramsData.searchText"
            style="width:340px"
          >
          </el-input>
        </el-form-item>
       
        <el-button
          size="mini"
          type="primary"
          @click="search"
          style="width:80px;height:40px"
          >查询</el-button
        >
        <el-button
          size="mini"
          class="reset"
          style="margin-left: 10px;width:80px;height:40px"
          @click="resize"
          >重置</el-button
        >
      </el-form>
    </div>
    <div class="modalityCenter">
     <el-button
          size="mini"
          type="primary"
          class="addButton"
          @click="addGroup"
          >添加</el-button
        >
    <el-table
      :data="tableData"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      max-height="550"
      :cell-style="{textAlign:'center'}"
      :header-cell-style="{
        fontFamily: 'MicrosoftYaHeiUI',
        fontSize: '14px',
        textAlign: 'center',
        fontWeight: 900,
        background: '#DCDFE8',
      }"
    >
     <el-table-column type="index" align="center" label="序号" width="80"></el-table-column>
      <el-table-column property="taskCode" label="任务编号" align="center" show-overflow-tooltip/>

      <el-table-column property="taskName"
        label="任务名称"
        align="center"
        show-overflow-tooltip
      ></el-table-column>
      <el-table-column
        property="executorPeopleName"
        label="巡检区域"
        align="center"
      ></el-table-column>
      <el-table-column
        property="lineLength"
        label="管线长度（km）"
        align="center"
      ></el-table-column>
      <el-table-column
        property="checkingPoint"
        label="巡检点个数"
        align="center"
      ></el-table-column>
      <el-table-column
        property="punch"
        label="打卡点个数"
        align="center"
      ></el-table-column>
      <el-table-column
        property="executorPeopleName"
        label="巡线员"
        align="center"
      ></el-table-column>
      <el-table-column
        property="taskFrequency"
        label="执行频率（次/天）"
        align="center"
      ></el-table-column>
      <!-- <el-table-column property="taskType" label="执行类型" align="center">
        <template slot-scope="{ row }">
          {{ row.taskType == "one" ? "执行一次" : "执行多次" }}
        </template>
      </el-table-column> -->
      <el-table-column property="executionTime" label="执行时间" align="center">
        <template slot-scope="{ row }">
          <!--          {{row.executionTime.split(',').length == 1 ? row.executionTime:`${row.executionTime.split(',')[0]} 至 ${row.executionTime.split(',')[1]}`}}-->
          {{
            row.taskType == "one"
              ? row.taskExecuteOnceDate
              : `${row.taskDurationStartTime} 至 ${row.taskDurationEndTime}`
          }}
        </template>
      </el-table-column>
      <el-table-column property="createTime" label="创建时间" align="center">

      </el-table-column>
      <el-table-column
        property="taskStatusName"
        label="执行状态"
        align="center">

        <!-- <template slot-scope="{ row }"> -->
          <!--          {{row.executionTime.split(',').length == 1 ? row.executionTime:`${row.executionTime.split(',')[0]} 至 ${row.executionTime.split(',')[1]}`}}-->
          <!-- {{ exeStatus(row.taskGenerateStatus) }}
        </template> -->
      </el-table-column>
      <el-table-column label="操作" align="left">
        <template slot-scope="{ row }">
          <!-- <el-button type="text" @click="seeDetil(row)" size="small">查看</el-button> -->
          <!-- <el-button
            type="text"
            v-if="row.taskGenerateStatus == 3"
            @click="IsOpenFun(0, row)"
            >开启</el-button
          > -->
          <el-button
            type="text"
            v-if=" row.taskGenerateStatus == 1"
            @click="IsOpenFun(4, row)"
            >关闭</el-button
          >
          <el-button type="text"  v-if="row.taskGenerateStatus == 0" @click="handle(row)" size="small"
            >编辑</el-button
          >
          <el-button
            type="text"
            @click="del(row)"
            size="small"
            style="color:#FF5400"
            v-if="row.taskGenerateStatus == 0"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <div style="text-align: center">
      <el-pagination
        @size-change="handleSizeChange"
        style="margin-top: 10px"
        background
        layout="total,sizes,prev, pager, next,jumper"
        :total="total"
        :page-sizes="[15, 30, 50, 100]"
        :page-size="paramsData.size"
        :current-page="paramsData.current"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
    </div>
   
    <el-dialog title="任务配置详情" :visible.sync="detilVisible">
      <table class="view-table">
        <tr>
          <th>执行人姓名</th>
          <td>
            <span>{{ detiltable.executorPeopleName }}</span>
          </td>
          <th>片区名称</th>
          <td>
            <span>{{ detiltable.networkName }}</span>
          </td>
        </tr>
        <tr>
          <th>任务名称</th>
          <td>
            <span>{{ detiltable.taskName }}</span>
          </td>
          <th>任务编号</th>
          <td>
            <span>{{ detiltable.taskCode }}</span>
          </td>
        </tr>
        <tr>
          <th>巡检点个数</th>
          <td>
            <span>{{ detiltable.checkingPoint }}</span>
          </td>
          <th>打卡点个数</th>
          <td>
            <span>{{ detiltable.punch }}</span>
          </td>
        </tr>
        <tr>
          <th>计划编码</th>
          <td>
            <span>{{ detiltable.planCode }}</span>
          </td>
          <th>管线总长度</th>
          <td>
            <span>{{ (detiltable.lineLength/1000).toFixed(2)}}km</span>
          </td>
        </tr>
        <tr>
          <th>执行类型</th>
          <td>
            <span>{{ detiltable.taskType == 'one'?'执行一次':'重复执行' }}</span>
          </td>
          <th>每天频率</th>
          <td>
            <span>{{ detiltable.taskFrequency }}</span>
          </td>
        </tr>
        <tr>
          <th>持续日期</th>
          <td colspan="3">
            <span v-if="detiltable.taskType == 'unone'">{{ detiltable.taskDurationStartTime }} - {{ detiltable.taskDurationEndTime }}</span>
            <span v-else>{{detiltable.taskExecuteOnceDate}}</span>
          </td>
        </tr>
        <tr>
          <th>说明</th>
          <td colspan="3">
            <span>{{ detiltable.taskInstructions }}</span>
          </td>
        </tr>
      </table>
      <span slot="footer" class="dialog-footer">
        <el-button @click="detilVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!--    <el-dialog-->
    <!--    title="操作任务"-->
    <!--    width="30%"-->
    <!--    :close-on-click-modal="false"-->
    <!--    :visible.sync="addVisible"-->
    <!--    @close="closeDialog('form')">-->
    <!--      <el-form ref="form" :model="form" label-width="100px" :rules="rule">-->
    <!--        <el-form-item label="任务执行人：">-->
    <!--          <el-select clearable style="width:100%;" value-key="userId" v-model="executor" placeholder="任务执行人">-->
    <!--            <el-option-->
    <!--                v-for="item in excutorDownArray"-->
    <!--                :key="item.id"-->
    <!--                :label="item.username"-->
    <!--                :value="item.id">-->
    <!--            </el-option>-->
    <!--          </el-select>-->
    <!--        </el-form-item>-->
    <!--        <el-form-item label="巡检片区:">-->
    <!--          <el-select class="point" clearable multiple collapse-tags v-model="form.checkpointList" placeholder="巡检点">-->
    <!--            <el-option-->
    <!--                v-for="item in selectDownArray"-->
    <!--                :key="item.id"-->
    <!--                :label="item.network_name"-->
    <!--                :value="item.id">-->
    <!--            </el-option>-->
    <!--          </el-select>-->
    <!--        </el-form-item>-->
    <!--      </el-form>-->
    <!--      <span slot="footer" class="dialog-footer">-->
    <!--        <el-button @click="addVisible = false">取 消</el-button>-->
    <!--        <el-button @click="submit" type="primary">确 定</el-button>-->
    <!--      </span>-->
    <!--    </el-dialog>-->

    <el-dialog title="添加任务" :visible.sync="addVisible" @closed="CloseChangePlan" width="540px">
      <el-form :model="DeviceData"  style="margin:0 52px;" ref="DeviceData" label-width="80px" :rules="rules">
        <el-form-item label="任务名称" prop="taskName">
          <el-input
            style="width: 300px"
            placeholder="请输入任务名称"
            v-model="DeviceData.taskName"
          ></el-input>
        </el-form-item>
        <el-form-item label="巡检计划" prop="planId">
          <el-select style="width: 300px" v-model="DeviceData.planId">
            <el-option
              v-for="item in patrolArray"
              :value="item.id"
              :label="
                '任务执行人：' +
                item.username +
                '巡检编号：' +
                item.plan_code
              "
              :key="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="执行类型">
          <el-radio-group v-model="DeviceData.taskType">
            <el-radio-button label="one">执行一次</el-radio-button>
            <el-radio-button label="unone">重复执行</el-radio-button>
          </el-radio-group>
        </el-form-item> -->
        <el-form-item label="执行频率" prop="taskFrequency">
          <el-input
            style="width: 300px"
            placeholder="请输入"
            onkeyup="value=value.replace(/[^\d]/g,'')"
            v-model="DeviceData.taskFrequency"
          >
          <template #suffix>次/天</template>
          </el-input>
        </el-form-item>
        <div v-if="DeviceData.taskType == 'one'">
          <el-form-item label="执行时间" prop="taskExecuteOnceDate">
            <el-date-picker
              value-format="yyyy-MM-dd"
              style="width: 300px"
              v-model="DeviceData.taskExecuteOnceDate"
              type="date"
              placeholder="选择日期时间"
              :picker-options="pickerOptions0"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <div v-if="DeviceData.taskType == 'unone'">
          <!--          <el-form-item label="执行频率">-->
          <!--            <el-radio-group v-model="DeviceData.planFrequency" size="medium">-->
          <!--              <el-radio-button label="day">每天</el-radio-button>-->
          <!--              <el-radio-button label="week">每周</el-radio-button>-->
          <!--              <el-radio-button label="month">每月</el-radio-button>-->
          <!--            </el-radio-group>-->
          <!--          </el-form-item>-->
          <!--              <el-form-item v-if="DeviceData.planFrequency == 'week'">-->
          <!--                <el-checkbox-group v-model="WeekSelectArray">-->
          <!--                  <el-checkbox-->
          <!--                      v-for="(item) in WeekArray"-->
          <!--                      :label="item.value"-->
          <!--                      :key="item.value">-->
          <!--                    {{item.name}}-->
          <!--                  </el-checkbox>-->
          <!--                </el-checkbox-group>-->
          <!--              </el-form-item>-->
          <!--              <el-form-item label="" v-if="DeviceData.planFrequency == 'month'">-->
          <!--                <el-checkbox-group v-model="MountSelectArray">-->
          <!--                  <el-checkbox-->
          <!--                      v-for="(item) in monthArray"-->
          <!--                      :label="item"-->
          <!--                      :key="item"-->
          <!--                  >-->
          <!--                    {{item}}-->
          <!--                  </el-checkbox>-->
          <!--                </el-checkbox-group>-->
          <!--              </el-form-item>-->
          <!-- <el-form-item label="每天频率" prop="taskFrequency">
            <el-input
              type="number"
              v-model="DeviceData.taskFrequency"
            ></el-input> -->
            <!--            <el-time-picker-->
            <!--                    value-format="HH:mm:ss"-->
            <!--                    v-model="DeviceData.planDurationDailyTime"-->
            <!--                    placeholder="任意时间点">-->
            <!--            </el-time-picker>-->
          <!-- </el-form-item> -->
          <el-form-item label="持续日期" prop="StartAndEndTime">
            <el-date-picker
                @input="changePicker"
              value-format="yyyy-MM-dd"
              v-model="DeviceData.StartAndEndTime"
              type="daterange"
              @change="DatePickerFun"
              style="width: 300px"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions0"
            >
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item label="说明">
          <el-input v-model="DeviceData.taskInstructions" type="textarea" style="width: 300px">
          </el-input>
        </el-form-item>
      </el-form>
      <div style="text-align: center;margin-top:54px;">
        <el-button @click="submitPatrol" type="primary"  style="width:160px;height:40px;margin:0 30px;">确 认</el-button>
        <el-button @click="addVisible = false"  style="width:160px;height:40px;margin:0 30px;">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  checkpointlist,
  deletetask,
  getdetillist,
  taskpage,
  updatetask,
  getNetwork,
  getUserList,
  planInfos,
  isOpen,
} from "../../RequestPort/maintenance/task";
import { ByPostpeople } from "../../RequestPort/maintenance";
import { ChangePlan, taskLine } from "@/RequestPort/Inspection/inspePlan";
export default {
  name: "inspeTask",
  data() {
    return {
      pickerOptions0: {
          disabledDate(time) {
            return time.getTime() < Date.now() - 8.64e7;
          }
        },  
//任务状态 0-未开始 1-进行中 2-部分完成3 已完成

        options: [{
          value: '0',
          label: '未开始'
        }, {
          value: '1',
          label: '进行中'
        }, {
          value: '3',
          label: '已结束'
        }],
        // 查询条件
      paramsData: {
        current: 1,

        size: 15,
        taskGenerateStatus:null,
      },
      executor: {},
      cooperate: {},
      // peopleList: [],
      form: {
        checkpointList: [],
        pipelineList: [],
      },
      rules: {
        taskName: [
          { required: true, message: "请输入任务名称", trigger: "blur" },
        ],
        planId: [{ required: true, message: "请选择巡检计划", trigger: "change" }],
     
        taskExecuteOnceDate: [{ required: true, message: "请选择任务时间", trigger: "change" }],
        taskFrequency: [
          { required: true, message: "请输入每天频率", trigger: "change" },
          { required: true, message: "请输入每天频率", trigger: "blur" },
        ],
         StartAndEndTime: [{ required: true, message: "请选择持续日期", trigger: "change" }],
      },
      detiltable: {},
      // 新增任务
      addVisible: false,
      // 详情弹窗
      detilVisible: false,
      total: 0,
      DataPicker: [],
      pointList: [],
      lineList: [],
      selectpointList: [],
      selectline: [],
      tableData: [],
      multipleSelection: [],
      // 片区字典，巡检区域
      selectDownArray: [],
      // 巡线人员
      excutorDownArray: [],
      patrolArray: [],
      // 新增任务对象
      DeviceData: {
        planDurationDailyTime: "",
        planDurationEndTime: "",
        planDurationStartTime: "",
        planDurationType: 0,
        planExecuteOnceDate: "",
        planFrequency: "day",
        planInstructions: "",
        planMonthDay: "",
        planName: "",
        planTaskConfigIds: [],
        taskType: "unone",
        planWeek: "",
        StartAndEndTime:[],
      },
      ExecutionTime: "",
      StartAndEndTime: [],
    };
  },
  watch:{
    // 'DeviceData.StartAndEndTime'(e){
    //   this.$forceUpdate()
    // }
  },
  methods: {
    changePicker(){
      this.$forceUpdate()
    },
    DatePickerFun(e){
      this.$forceUpdate()
    },
    exeStatus(e) {
      let Name = "";
      switch (e) {
        case 0:
          Name = "未开始";
          break;
        case 1:
          Name = "进行中";
          break;
        case 2:
          Name = "已完成";
        case 3:
          Name = "已结束";
          break;
        case 4:
          Name = "已关闭";
          break;
      }
      return Name;
    },
    IsOpenFun(e, row) {
      if(e == 4){
        this.$confirm('此操作将关闭该任务, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal:false,
          type: 'warning'
        }).then(() => {
          isOpen({ id: row.id, taskGenerateStatus: e }).then((e) => {
            this.$message.success("修改成功");
            this.loadlist(this.paramsData);
          });
        }).catch(() => {

      })}else{
        isOpen({ id: row.id, taskGenerateStatus: e }).then((e) => {
          this.$message.success("修改成功");
          this.loadlist(this.paramsData);
        });
      }
    },
    CloseChangePlan() {
      this.DeviceData = {
        planDurationDailyTime: "",
        planDurationEndTime: "",
        planDurationStartTime: "",
        planDurationType: 0,
        planExecuteOnceDate: "",
        planFrequency: "day",
        planInstructions: "",
        planMonthDay: "",
        planName: "",
        planTaskConfigIds: [],
        taskType: "unone",
        planWeek: "",
        StartAndEndTime:''
      };
      this.StartAndEndTime = [];
      this.WeekSelectArray = [];
      this.MountSelectArray = [];
      this.$refs.DeviceData.resetFields()
    },
    /**
     * 新增任务
     */
    addGroup() {
      this.addVisible = !this.addVisible;
    },
    /**
     * 配置详情
     */
    seeDetil(e) {
      this.detilVisible = !this.detilVisible;
      this.detiltable = e;
      // getdetillist({id:id}).then(res=>{
      //   this.detiltable = res.data
      // })
    },
    closeDialog(form) {
      this.$refs[form].resetFields();
      this.form = {
        checkpointList: [],
        pipelineList: [],
      };
      this.executor = {};
      this.cooperate = {};
    },
    handle(row) {
      // this.executor={
      //   userName:row.executorPeopleName,
      //   userId:row.executorPeopleId
      // }
      // this.cooperate={
      //   userName:row.cooperatePeopleName,
      //   userId:row.cooperatePeopleId
      // }
      // this.form.checkpointList = row.checkpointList
      // this.form.pipelineList = row.pipelineList
      // this.form.id = row.id
      // this.StartAndEndTime = row.executionTime.split(',')
      this.DeviceData = JSON.parse(JSON.stringify(row));
      Object.keys(row).forEach((item,index)=>{
        this.DeviceData[item] = row[item]||this.DeviceData[item]
      })
      if (row.taskType == "one") {
        // this.StartAndEndTime = row.executionTime.split(',')
      } else {
        this.DeviceData.StartAndEndTime = [
          row.taskDurationStartTime,
          row.taskDurationEndTime,
        ];
      }
      // if(row.executionTime)
      this.addVisible = !this.addVisible;
    },
    /**
     * 新增任务
     */
    submitPatrol() {
      this.$refs.DeviceData.validate((valid) => {
        if (valid) {
          if(this.DeviceData.taskType != "one"){
            if (this.DeviceData.StartAndEndTime&&this.DeviceData.StartAndEndTime.length > 0) {
              this.DeviceData.taskDurationStartTime = this.DeviceData.StartAndEndTime[0];
              this.DeviceData.taskDurationEndTime = this.DeviceData.StartAndEndTime[1];
            }
          }else{
            this.DeviceData.taskFrequency = 1;
          }
          updatetask(this.DeviceData).then((res) => {
            this.addVisible = !this.addVisible;
            this.$message.success("操作成功");
            this.loadlist();
          });
        }
      });
      // if(this.DeviceData.taskType == ''){
      //   this.$message.error('请选择执行类型')
      //   return
      // }
      // if(this.ExecutionTime != ''){
      //   this.DeviceData.planExecuteOnceDate = this.ExecutionTime.split(' ')[0]
      //   this.DeviceData.planDurationDailyTime = this.ExecutionTime.split(' ')[1]
      // }

      // if(this.WeekSelectArray.length>0){
      //   let stringArray = ''
      //   this.WeekSelectArray.forEach((item,index)=>{
      //     stringArray+=item+','
      //   })
      //   this.DeviceData.planWeek = stringArray
      // }
      // if(this.MountSelectArray.length>0){
      //   let MounthString = ''
      //   this.MountSelectArray.forEach((item,index)=>{
      //     MounthString+=item+','
      //   })
      //   this.DeviceData.planMonthDay = MounthString
      // }
      // ChangePlan(this.form).then((e)=>{
      //   this.getList()
      //   this.$message.success('操作成功')
      //   this.addVisible = !this.addVisible
      // })
    },
    submit() {
      if (this.executor.userId) {
        if (
          this.form.checkpointList.length < 1 &&
          this.form.pipelineList.length < 1
        ) {
          return this.$message.error("请选择巡检点或管线");
        }
        (this.form.cooperatePeopleId = this.cooperate.userId),
          (this.form.cooperatePeopleName = this.cooperate.userName),
          (this.form.executorPeopleId = this.executor.userId),
          (this.form.executorPeopleName = this.executor.userName),
          updatetask(this.form).then((res) => {
            if (res.code === 200) {
              this.addVisible = !this.addVisible;
              this.loadlist(this.paramsData);
              this.$message.success(res.msg);
              this.selectpointList = [];
              this.selectline = [];
              this.executor = {};
              this.cooperate = {};
            } else {
              this.$message.error(res.msg);
            }
          });
      } else {
        return this.$message.error("未选择执行人");
      }
    },
    /**
     * 查询
     */
    search() {
      if (this.DataPicker&&this.DataPicker.length > 0) {
        this.paramsData.startTime = this.DataPicker[0];
        this.paramsData.endTime = this.DataPicker[1];
      }else{
        this.paramsData.startTime =null;
        this.paramsData.endTime = null;
      }
      this.paramsData.current = 1;
      this.loadlist(this.paramsData);
    },
    handleCurrentChange(val) {
      this.paramsData.current = val;
      this.loadlist(this.paramsData);
    },
    handleSizeChange(val) {
      this.paramsData.size = val;
      this.loadlist(this.paramsData);
    },

    // changeType(e){
       
    //   this.paramsData = {
    //     current: 1,
    //     size: 15,
    //     taskGenerateStatus:e
    //   };
    //   this.DataPicker = [];
    //   this.loadlist(this.paramsData);

    // },
  /**
   * 重置
   */
    resize() {
      this.paramsData = {
        current: 1,
        size: 15,
        searchText: "",
      };
      this.DataPicker = [];
      this.loadlist(this.paramsData);
    },
    /**
     * 复选
     */
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**
     * 删除
     */
    del(row) {
      this.$confirm(`是否确定删除?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal:false,
        type: "warning",
      }).then(() => {
        let id = [];
        id.push(row.id);
        deletetask({ ids: id }).then((res) => {
          this.$message({
            message: "删除成功",
            type: "success",
          });
          this.paramsData.current = 1;
          this.loadlist(this.paramsData);
        });
      });
    },
    // MutiDeleteFun() {
    //   if (this.multipleSelection.length > 0) {
    //     this.$confirm(`是否批量删除?`, "提示", {
    //       confirmButtonText: "确定",
    //       cancelButtonText: "取消",
    //       type: "warning",
    //     }).then(() => {
    //       let arr = [];
    //       this.multipleSelection.forEach((item) => {
    //         arr.push(item.id);
    //       });
    //       deletetask({ ids: arr }).then((res) => {
    //         if (res.code === 200) {
    //           this.$message({
    //             message: "删除成功",
    //             type: "success",
    //           });
    //           this.paramsData.current = 1;
    //           this.loadlist(this.paramsData);
    //         } else {
    //           this.$message({
    //             message: "删除失败",
    //             type: "error",
    //           });
    //         }
    //       });
    //     });
    //   } else {
    //     this.$message.error("未选择删除项目");
    //   }
    // },
    /**
     * 获取列表
     */
    loadlist(obj) {
      taskpage(obj).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
  },
  mounted() {
    // 巡检区域字典，片区下拉
    getNetwork().then((e) => {
      this.selectDownArray = e.data;
    });
    // 获取巡线员
    getUserList({type:2}).then((e) => {
      this.excutorDownArray = e.data;
    });
    this.loadlist(this.paramsData);
    // ByPostpeople({ code: "pollingPerson" }).then((res) => {
    //   this.peopleList = res.data;
    // });
    // checkpointlist().then(res=>{
    //   this.pointList = res.data
    // })
    /**
     * 巡检计划
     */
    planInfos().then((e) => {
      this.patrolArray = e.data;
    });
    // checklinelist().then(res=>{
    //   this.lineList = res.data
    // })
  },
};
</script>

<style scoped lang="less">
.point {
  width: 100%;
  
}
.contain{
  background-color:transparent;
}
// 搜索栏样式
.modalityTop{
    border: 1px solid #ecedf1;
    padding: 20px;
    border-radius: 5px;
    background: #ffffff;
    margin-bottom: 12px;
  // 面包屑
  .crumbs{
    padding: 10px 15px 20px;
    span{
      color: #3069E1;
    }
  }
}
// 搜索
.searchForm{
  display: flex;
   flex-wrap: wrap;
    justify-content: space-between;
  .el-form-item{
    margin: 0;
  }
}
// 表格样式
.modalityCenter{
  background: #ffffff;
  padding: 20px;
  // 新增按钮
  .addButton{
    margin-bottom: 10px;
  }
}
</style>
